import React, { Component } from "react";
import 'date-fns';
import { Navbar,Nav,Table} from "react-bootstrap";
//import { Particles } from './Original.js';
import nlLocale from 'date-fns/locale/nl';
import enLocale from 'date-fns/locale/en-US';
import * as moment  from 'moment';
import "moment/locale/be";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";


var zhr; var whr;
const localeMap = {
  en: enLocale,
  nl: nlLocale,
};
const maskMap = {
  nl: '__/__/____',
  en: '__/__/____',
  ru: '__.__.____',
  de: '__.__.____',
};

class App extends Component {
  render() {
    return (
      <Router>
        <Navbar  bg="dark" variant="dark" expand="lg" sticky="top" >
            <Navbar.Brand href="#home">
             <img width="250px" height="auto" className="img-responsive" src='logo.jpg' alt="logo" />
            </Navbar.Brand>
            
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="nav-text">
                <Nav.Link href="/">Klemmenstatus</Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
        <br />
        <div>
          <Routes>
            <Route exact path='/webtraps' element={<Home/>}/>
            <Route exact path='/' element={<Home/>}/>
             
           
          </Routes>
        </div>
      </Router>
    );
  }
}

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {start: new Date()};
    
    this.state = {
      pressure: "..."
    };
    this.state = {
      localeVar: nlLocale
    };
    
    this.dylosRequest = this.dylosRequest.bind(this)
    this.state = {
      dylos: [],
    };
    //this.setDateValue = this.setDateValue.bind(this)
    
    
  }

  componentDidMount() {
    document.title = "Cyber Traps Status Vallen";
    whr = new XMLHttpRequest();
    whr.open("GET","https://dev.winsys.be:443/api/GetTrapState", true);
    whr.send();
    whr.addEventListener("readystatechange", this.dylosRequest, false);
  }
  
  dylosRequest() {
    if (whr.readyState === 4 && whr.status === 200) {
      var response = JSON.parse(whr.response);
      this.setState({dylos: response});
    } 
  }
  
  render() {
    return (

      <div>
        <h1>&nbsp;Cloud Traps Overzicht</h1>
        <hr></hr>
        <h8>&nbsp;<img width="15px" height="auto" className="img-responsive" src='greenbullet.jpg'  alt="green" /> Opgespannen &nbsp;<img width="15px" height="auto" className="img-responsive" src='redbullet.jpg'  alt="yellow" /> Afgegaan &nbsp;<img width="15px" height="auto" className="img-responsive" src='yellowbullet.jpg'  alt="yellow" /> Niet Gemeld</h8>
        <div>
        <br />
        </div>
        <div>
          <Table>
              <NumberParticleList cpeconst = {this.state.dylos}/> 
          </Table>
            
        </div>
      </div>
     );
  }
};

class  NumberParticleList extends Component {
  render() {
  const listcpe = this.props.cpeconst.map((cpe) => 
  <tr>
  <td><img width="15px" height="auto" src = {cpe[0]}/></td>
  <td> {cpe[1]}</td>
  <td> {cpe[3]}</td>
  <td>{cpe[5]}</td>
  </tr> 
  );
    return(
      <tbody>
      <tr><th light>Status</th><th>Val</th><th>Volt</th><th>Laatst Gemeld</th></tr>
      {listcpe}
      </tbody>
    );
  }
}



export default App;

